import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
//import { triggerWhenVisible } from '../../libs/commons';
//import { getPrevSibling } from '../../libs/utils';




class ProductsSlider extends Component {

    constructor(container) {
        super('widget-products-slider');


        this.container = container.querySelector(`${this._el('container',true)}`);
        
        this._initSwiper(container);
    }

    async _initSwiper() {

        if (this.swiper)
            this._destroySwiper();

        const Swiper = await import('swiper');
        let _swiperDefaults = {
            modules: [Swiper.Autoplay, Swiper.EffectFade, Swiper.Navigation, Swiper.Pagination],
            slidesPerView: '1',
            spaceBetween: 12,
            speed: 700,
            roundLengths: true,
            observer: true,
            watchOverflow: true,
            preventInteractionOnTransition: true,
            simulateTouch: false,
            nested: true,
            watchSlidesVisibility: true,
            breakpoints: {
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                  spaceBetween: 12
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 16
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            /*on: {
                transitionEnd: (swiper) => {
                    const className = window.deviceBreakpoints.bpMobile.matches === true ? '.swiper-slide-active' : '.swiper-slide-visible';
                    const visibleSlides = [...this.container.querySelectorAll(`.swiper-slide${className}`)];

                    visibleSlides.forEach((item) => {
                        const title = item.querySelector(`${this._el('slide-title',true)}`);
                        const cta = item.querySelector(`${this._el('slide-cta',true)}.btn-primary`);
                        //console.log(swiper,item,title,cta);
                        this._sendAnalytics(
                            'impression',
                            title.textContent.trim(),
                            cta.href,
                            swiper.realIndex + 1,
                        );
                    });
                }
            }*/
        };

        
        this.swiper = new Swiper.default(this.container, _swiperDefaults);

        /*const ctaList = this.container.querySelectorAll(`${this._el('slide-cta',true)}.btn-primary`);
        ctaList.forEach((item) => {
            item.addEventListener('click', ({target}) => {
                const textContainer = getPrevSibling(target.parentElement,this._el('slide-title-text-price',true))
                const title = textContainer.querySelector(`${this._el('slide-title',true)}`);
                this._sendAnalytics(
                    'click',
                    title ? title.textContent.trim() : '',
                    target.href,
                    this.swiper.realIndex + 1,
                );
            });
        });*/

        /*triggerWhenVisible(this.container, () => {
            const count = window.deviceBreakpoints.bpMobile.matches === true ? 1 : window.deviceBreakpoints.bpDesktop.matches ? 3 : 2;
            const slides = this.container.querySelectorAll(`${this._el('slide',true)}`);

            for (let i = 0; i < count; i++) {
                const title = slides[i].querySelector(`${this._el('slide-title',true)}`);
                const cta = slides[i].querySelector(`${this._el('slide-cta',true)}.btn-primary`);
                this._sendAnalytics(
                    'impression',
                    title.textContent.trim(),
                    cta.href,
                    1,
                );
            };
        }, true);*/
    }

    _sendAnalytics(action,label,url,position) {

        analytics.sendData({
            'event': 'slider',
            'action': action,           // "impression" or "click"
            'label': label,             // Product name
            'click_url': url || '',     // Primary CTA href
            'position': position        // Swiper slide position
        });
    }
}

register.registerClass('.widget-products-slider', ProductsSlider);