import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { triggerWhenVisible } from '../../libs/commons';




class RoundImages extends Component {

    constructor(container) {
        super('widget-round-images');

        this.container = container.querySelector(`${this._el('container',true)}`);
        this.slideItems = container.querySelectorAll(`${this._el('slide',true)}`);
        this.links = container.querySelectorAll(`${this._el('overlay-link',true)}`);

        document.addEventListener('bpMobile', () => {
            if (this.slideItems.length > 2)
                this._initSwiper();
        });
        document.addEventListener('bpTablet', () => {
            if (this.slideItems.length > 3)
                this._initSwiper();
        });
        document.addEventListener('bpDesktop', () => this._destroySwiper() );
        
        this._init();
    }

    _init() {

        this.links.forEach((el) => {

            if (el.href) {
                el.addEventListener('click', (ev) => { 
                    ev.preventDefault();
                    ev.stopPropagation();

                    if (window.deviceBreakpoints.bpDesktop.matches === false) {
                        const title = ev.target.querySelector(`${this._el('label',true)}`);
                        this._sendAnalytics(
                            'click',
                            title ? title.textContent.trim() : '',
                            ev.target.href || null,
                            this._getActiveSlideIndex(ev.target.parentNode) + 1
                        );
                    }

                    this._scrollToSection(el);
                });

                //console.debug(el.href);
            }
        });

        if ((window.deviceBreakpoints.bpMobile.matches === true 
            && this.slideItems.length > 2) ||
            (window.deviceBreakpoints.bpTablet.matches === true
            && this.slideItems.length > 3)) {
            this._initSwiper();
        }

        triggerWhenVisible(this.container, () => {

            if (window.deviceBreakpoints.bpDesktop.matches === false) {
                const count = window.deviceBreakpoints.bpMobile.matches === true ? 2 : 3;
                const slides = this.container.querySelectorAll(`${this._el('slide',true)}`);

                for (let i = 0; i < count; i++) {
                    const title = slides[i].querySelector(`${this._el('label',true)}`);
                    const cta = slides[i].querySelector(`${this._el('overlay-link',true)}`);
                    this._sendAnalytics(
                        'impression',
                        title.textContent.trim(),
                        cta ? cta.href || '' : '',
                        i + 1
                    );
                };
            }
        }, true);
    }
    
    async _initSwiper() {

        if (!this.swiper) {
            const Swiper = await import('swiper');
            let _swiperDefaults = {
                modules: [Swiper.Autoplay, Swiper.EffectFade, Swiper.Navigation, Swiper.Pagination],
                slidesPerView: '2',
                spaceBetween: 15,
                speed: 700,
                roundLengths: true,
                observer: true,
                watchOverflow: true,
                nested: true,
                watchSlidesVisibility: true,
                // centerInsufficientSlides: true,
                grabCursor: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: '3',
                        spaceBetween: 15,
                        grabCursor: true
                    },
                },
                on: {
                    slideChange: () => {
                        this.slideChanged = true;
                    },
                    touchEnd: () => {
                        this.touchEnded = true;
                    },
                    transitionEnd: () => {

                        if (this.slideChanged && this.touchEnded) {

                            this.slideChanged = false;
                            this.touchEnded = false;

                            const visibleSlides = [...this.container.querySelectorAll(`.swiper-slide.swiper-slide-visible`)];

                            visibleSlides.forEach((item) => {
                                const title = item.querySelector(`${this._el('label',true)}`);
                                const cta = item.querySelector(`${this._el('overlay-link',true)}`);
                                //console.log(swiper,item,title,cta);
                                this._sendAnalytics(
                                    'impression',
                                    title.textContent.trim(),
                                    cta? cta.href || '': '',
                                    this._getActiveSlideIndex(item) + 1
                                );
                            });
                        }
                    }
                }
            };

            this.swiper = new Swiper.default(this.container, _swiperDefaults);
        } else {
            this.swiper.update();
        }
    }

    _destroySwiper() {

        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }

    _scrollToSection(el) {
        let value = el.getAttribute('href');

        if (value) {
            value = value.replace("#", "");
            let element = document.getElementById(value);

            if (element) {
                // Get the size and position of our element in the viewport
                //const containerBox = element.getBoundingClientRect();
                let offsetTop = element.offsetTop;

                let scrollOptions = {
                    left: 0,
                    top: offsetTop,
                    behavior: 'smooth'
                }
                
                window.scrollTo(scrollOptions);
            }
        }
    }

    _getActiveSlideIndex(item) {
        return Array.prototype.indexOf.call(item.parentNode.children, item);
    }

    _sendAnalytics(action,label,url,position) {

        analytics.sendData({
            'event': 'slider',
            'action': action,           // "impression" or "click"
            'label': label,             // Product name
            'click_url': url || '',     // Primary CTA href
            'position': position        // Swiper slide position
        });
    }
}

register.registerClass('.widget-round-images', RoundImages);