import Component from '../../libs/component';
import Pristine from '../../libs/vendor/pristine.min';
import { register } from '../../libs/register';
import { request } from '../../libs/utils';
import { analytics } from '../../libs/analytics';
import { vatCodeCheck, fiscalCodeCheck } from '../../libs/validation';
import 'configurable-date-input-polyfill';

class FormSteps extends Component {
    constructor(container) {
        super('widget-form-steps');

        this.container = container;
        this.form = container.querySelector('form');
        this.formId = this.form.id;
        this.formServletPath = this.form.dataset.url || '';
        this.modal = this.container.closest('.widget-modal-form');
        this.formType = container.dataset.type || 'client';

        this.siteKey = window.nexinew ? window.nexinew.recaptchasitekey || '' : '';
        this.recaptchaEnabled = this.siteKey ? Boolean(this.form.dataset.recaptchaEnabled) : false;
        
        this.stepsContainer = this.form.querySelector(`${this._el('steps-container',true)}`);
        this.activeStep = this.stepsContainer.querySelector(`${this._el('step',true)}.active`);
        this.activeStepIndex = 1;
        this.stepsTotal = [...this.form.querySelectorAll(`${this._el('step',true)}`)].length;
        this.formStepsTotal = [...this.form.querySelectorAll(`fieldset${this._el('step',true)}`)].length;
        
        this.progressBar = this.container.querySelector(`${this._el('progressbar',true)} progress`);
        this.progressBar.setAttribute('max',this.stepsTotal);
        this.progressBar.setAttribute('value',1);

        this.btnBack = this.container.querySelector(`${this._el('form-btn',true)}[data-role=back]`);
        this.btnNext = this.container.querySelector(`${this._el('form-btn',true)}[data-role=next]`);
        this.btnSend = this.container.querySelector(`${this._el('form-btn',true)}[data-role=send]`);
        this.btnClose = this.container.querySelector(`${this._el('form-btn',true)}[data-role=close]`);

        this.container.addEventListener('formStepsInit', () => {
            this._initForm();
        });
    }

    _initForm() {
        console.log('Opening',this.formType,'form');

        if (!this.container.classList.contains('init')) {
        
            const formFooter = this.form.querySelector(`${this._el('form-footer',true)}`);

            formFooter.addEventListener('click', ({target:btn}) => {

                switch(btn.dataset.role) {
                    case 'next':
                        this._nextStep();
                        break;
                    case 'back':
                        this._previousStep();
                        break;
                    case 'send':
                        this._sendFormData();
                        break;
                    case 'close':
                        this._closeModalForm();
                        break;
                }
            });

            this.container.addEventListener('formStepsReset', () => {
                this._resetForm();
            });

            this._initValidation();
            this._initRecaptcha();

            this.container.classList.add('init');
        }

        this._showStep(this.activeStepIndex);
        this._showProgressBar();
        this._updateProgressBar(this.activeStepIndex);
    }

    _initValidation() {

        const formOptions = {
            classTo: 'widget-form-steps__input',
            errorClass: 'error',
            successClass: 'valid',
            errorTextParent: 'widget-form-steps__input',
            errorTextTag: 'div',
            errorTextClass: 'error-text'
        };
        this.formValidate = new Pristine(this.form, formOptions, false);

        const lettersFields = [...this.form.querySelectorAll(`[name=name],[name=surname],[name=businessname]`)];
        lettersFields.forEach((item) => {
            item.addEventListener('keydown', (ev) => {
                if (!isNaN(parseFloat(ev.key)) && isFinite(ev.key)) // Checks if there is a numeric value
                    ev.preventDefault();
            });
        });

        const dateField = this.form.querySelector('input[type=date]');
        if (dateField) {
            dateField.addEventListener('focusout', ({target}) => {
                this._validateFormInput(target);
                this._checkInputError(target);
            });
        }

        const numberFields = [...this.form.querySelectorAll(`input[type=tel]`)];
        numberFields.forEach((item) => {
            item.addEventListener('keydown', (ev) => {
                if (isNaN(parseFloat(ev.key)) && !isFinite(ev.key)) // Checks if there is not a numeric value
                    if (ev.key != "Backspace" && ev.key != "Delete" && ev.key != "ArrowLeft" && ev.key != "ArrowRight" && ev.key != "Tab")
                        ev.preventDefault();
            });
        });

        const vatcodeField = this.form.querySelector(`input[name=vatcode]`);

        if (vatcodeField) {

            vatcodeField.addEventListener('keyup', (ev) => {
                ev.target.value = ev.target.value.toUpperCase();
            });

            vatcodeField.addEventListener('keydown', (ev) => {
                if(!(ev.key >= '0' && ev.key <= '9') && !ev.key.match(/[a-zA-Z]/)) // Checks if there is not alphanumeric value
                    if (ev.key != "Backspace" && ev.key != "Delete" && ev.key != "ArrowLeft" && ev.key != "ArrowRight" && ev.key != "Tab")
                    ev.preventDefault();
            });

            this.formValidate.addValidator(vatcodeField, function(value) {
                if (value.length == 11) {
                    return vatCodeCheck(value);
                } else if (value.length == 16) {
                    return fiscalCodeCheck(value);
                }else if (value.length > 11 && value.length < 16) {
                    return false;
                } else {
                    return true;
                }

            }, vatcodeField.dataset.pristineVatcodeMessage, 1, true);
        }

        this.form.addEventListener('focusin', ({target}) => {
            this._sendInteractionAnalytics(target,'focus');
        });

        this.form.addEventListener('change', ({target}) => {
            if (target.type == 'date')
                return;

            this._validateFormInput(target);
            this._checkInputError(target);
        });
    }

    _validateFormInput(input) {

        if (this.formValidate)
            this.formValidate.validate(input);

        this._handleButtonsForward( this._activeStepValid() );
    }

    _checkInputError(input) {
        const inputContainer = input.closest(`${this._el('input',true)}.error`);
        
        if (inputContainer)
            this._sendErrorAnalytics(input,'input');
    }

    _activeStepValid() {

        const activeFieldset = this.form.querySelector(`fieldset.active ${this._el('form-fields-group',true)}.active`);
        const merchantFieldset = this.form.querySelector(`fieldset ${this._el('form-fields-group',true)}.merchant.active`);


        if (this.formValidate && activeFieldset) {
            const stepFields = activeFieldset.querySelectorAll(`${this._el('input',true)} input, ${this._el('input',true)} textarea`);

            if(activeFieldset === merchantFieldset) {
                const vatcodeField = this.form.querySelector(`input[name=vatcode]`);
                const merchantField = this.form.querySelector(`input[name=merchantcode]`);
                
                if (vatcodeField)
                    if(vatcodeField.value == "" && merchantField.value == "") {
                        return false;
                    }
            }
           
            //console.log(stepFields)
            return this.formValidate.validate(stepFields,true);
        }

        return false;
    }

    _enableButton(btnRole, enable = true) {

        const button = this.form.querySelector(`button[data-role=${btnRole}]`);

        if (enable) {
            button.removeAttribute('disabled');
            button.classList.remove('disabled');
        } else {
            button.setAttribute('disabled','disabled');
            button.classList.add('disabled');
        }
    }

    _handleButtonsForward(formValid) {

        if (formValid) {
            this._enableButton('next', this.activeStepIndex < this.formStepsTotal);
            this._enableButton('send', this.activeStepIndex == this.formStepsTotal);
        } else {
            this._enableButton('next',false);
            this._enableButton('send',false);
        }
    }

    _hideButtons(hide = true, btnRole = null) {

        const buttons = [...this.form.querySelectorAll(`button[data-role${ btnRole ? '=' + btnRole : '' }]`)];

        buttons.forEach((item) => {
            hide ? item.classList.remove('active') : item.classList.add('active');
        });
    }

    _nextStep(showResult=false) {

        if (this.activeStepIndex < this.stepsTotal || showResult) {
            this.activeStepIndex++;
            this.stepsContainer.style.left = `-${(this.activeStepIndex - 1) * 100}%`;

            this._showStep(this.activeStepIndex,showResult);
            this._updateProgressBar();
        }
    }

    _previousStep() {

        if (this.activeStepIndex > 1) {
            this.activeStepIndex--;
            this.stepsContainer.style.left = this.activeStepIndex > 1 ? `-${(this.activeStepIndex - 1) * 100}%` : '0';

            this._showStep(this.activeStepIndex);
            this._updateProgressBar();
        }
    }

    _showStep(stepNum,showResult=false) {

        const steps = [...this.form.querySelectorAll(`${this._el('step',true)}`)];
        const stepIndex = stepNum <= this.stepsTotal ? stepNum : this.stepsTotal;

        steps.forEach((step,i) => {

            step.classList.remove('active');

            if (stepIndex == i+1) {

                step.classList.add('active');

                let fieldGroup = step.querySelector(`${this._el('form-fields-group',true)}.${this.formType}`);
                if (!fieldGroup) {
                    fieldGroup = step.querySelector(`${this._el('form-fields-group',true)}`);
                }
                if (fieldGroup)
                    fieldGroup.classList.add('active');
            }
        });

        this.form.scrollTo(0,0);

        this._hideButtons( this.activeStepIndex == 1 || this.activeStepIndex == this.stepsTotal,'back');
        this._hideButtons( this.activeStepIndex == this.formStepsTotal || this.activeStepIndex == this.stepsTotal,'next');
        this._hideButtons( this.activeStepIndex != this.formStepsTotal || this.activeStepIndex == this.stepsTotal,'send');
        this._hideButtons( this.activeStepIndex != this.stepsTotal,'close');

        if (!showResult)
            this._handleButtonsForward( this._activeStepValid() );
        else {
            this._hideProgressBar();
        }
    }

    async _sendFormData() {

        console.log('SENDING FORM DATA...')

        const validatedFields = [...this.form.querySelectorAll(`${this._el('input',true)}.valid input, ${this._el('input',true)}.valid textarea`)];
        const formData = new FormData();
        let recaptchaResponse = true;

        this._submitButtonSending();
        this._enableButton('send',false);
        this._enableButton('back',false);

        if (this.recaptchaEnabled && window.grecaptcha)
            recaptchaResponse = await this._checkRecaptcha(); // A returned Promise or False means rejected status

        if (recaptchaResponse === true || typeof recaptchaResponse === 'string') {
            formData.append('g-recaptcha-response',recaptchaResponse);

            validatedFields.forEach((el) => {
                //console.log(el.name, el.value);
                let value;
                if (el.type == 'date')
                    value = this._getFormattedDate(el.value);
                else if (el.type == 'checkbox')
                    value = el.value == 'on';
                else
                    value = el.value;

                formData.append(el.name, value);
            });

            const requestData = {
                method: 'POST',
                url: this.formServletPath,
                body: formData
            };
            
            request(requestData).
                then((resolve) => {
                    console.log('Send Form data SUCCESS',resolve.response);
                    this._onFormDataSent('success');
                    this._sendInteractionAnalytics(this.form,'send',true);
                })
                .catch((reject) => {
                    console.log(`Send Form Error: ${reject.status} / ${reject.response}`);
                    this._onFormDataSent('error');
                    this._sendInteractionAnalytics(this.form,'send',false,`Send Form Error: ${reject.status} / ${reject.response}`);
                    this._sendErrorAnalytics(this.form,'form',reject.status,'server',true);
                });

        } else {

            console.log('Send FormData Recaptcha Error');
            this._onFormDataSent('error');
            this._sendInteractionAnalytics(this.form,'send',false,'Recaptcha Error');
            this._sendErrorAnalytics(this.form,'form','','recaptcha',true);
        }
    }

    _onFormDataSent(result) {
        this._nextStep(true);
        this._showResultStep(result);
        this._submitButtonRevert();
        this._enableButton('send',true);
        this._enableButton('back',true);
    }

    _showResultStep(responseClass) {

        let resultSteps = [...this.form.querySelectorAll(`${this._el('form-result',true)}`)];

        resultSteps.forEach((el) => {
            if (el.matches(`.${responseClass}`))
                el.classList.add('active');
            else
                el.classList.remove('active');
        });
    }

    _getFormattedDate(stringDate) {

        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        }
          
        function formatDate(date) {
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('-');
        }

        return formatDate(new Date(stringDate));
    }

    _initRecaptcha() {

        const recaptchaContainer = this.form.querySelector('.recaptcha-container .g-recaptcha');

        if (this.siteKey && recaptchaContainer && window.grecaptcha)
            window.grecaptcha.ready(() => {
                this.gRecaptcha = window.grecaptcha.render(recaptchaContainer, {
                    'sitekey': this.siteKey,
                    //'badge': 'inline',
                    //'size': 'invisible'
                });
            });
    }

    async _checkRecaptcha() {
        console.log('FORM RECAPTCHA CHECK in progress...');

        try {
            return window.grecaptcha
                .execute(this.siteKey, {action: 'submit'})
                .then((token) => {
                    console.log('FORM RECAPTCHA RESPONSE:', token);
                    return token;
                })
                .catch((err) => console.warn(err));
        } catch(err) {
            console.warn(err);
            return false;
        }
    }

    _resetRecaptcha() {
        try {
            if (this.gRecaptcha !== null && this.gRecaptcha !== undefined)
                window.grecaptcha.reset(this.gRecaptcha);
            else
                window.grecaptcha.reset();
        } catch(err) {
            console.warn(err);
        }
    }

    _submitButtonSending() {
        const submit = this.form.querySelector(`button[data-role=send]`);
        submit.textContent = submit.dataset.sendingText;
    }

    _submitButtonRevert() {
        const submit = this.form.querySelector(`button[data-role=send]`);
        submit.textContent = submit.dataset.defaultText;
    }

    _showProgressBar() {
        this.progressBar.classList.remove('hidden');
    }

    _hideProgressBar() {
        this.progressBar.classList.add('hidden');
    }

    _updateProgressBar() {
        this.progressBar.setAttribute('value',this.activeStepIndex);
    }

    _closeModalForm() {
        const event = new CustomEvent('modalFormClose', { bubbles: true });
        document.body.dispatchEvent(event);
    }

    _resetForm() {

        this.activeStepIndex = 1;
        this.stepsContainer.style.left = '0';

        const fields = this.form.querySelectorAll(`input, textarea`);
        fields.forEach((el) => {
            el.value = '';
            if (el.type == 'checkbox')
                el.checked = false;
        });

        if (this.formValidate)
            this.formValidate.reset();

        this._resetRecaptcha();
        this._submitButtonRevert();
    }

    _sendInteractionAnalytics(target,type,success=null,msg=null) {

        if (target.nodeName === 'BUTTON') return;
        
        let payload = {
            'event': 'form_interaction',
            'form': {
                'action': type,
                'element': target.id,
                'send': {
                    'result': null,
                    'message': null
                }
            }
        };

        if (success !== null) {
            payload.form.send.result = success ? 1 : 0;
            payload.form.send.message = msg;
        }

        analytics.sendData(payload);
    }

    _sendErrorAnalytics(target,type='input',code='',reason='',fatal=false) {

        const payload = {
            'event': 'custom_error',
            'language': document.documentElement.lang,
            'error': {
                'type': type,
                'code': code,
                'description': '',
                'message': null,
                'field': '',
                'isFatal': fatal
            }
        },
        inputContainer = target.closest(`${this._el('input',true)}`);

        if (type === 'input') {
            payload.error.field = target.getAttribute('name') || target.getAttribute('id');
            payload.error.message = inputContainer.querySelector('.error-text').textContent.trim() || null;
        } else {
            const errorResultBlock = target.querySelector(`${this._el('form-result',true)}.error`);
            payload.error.message = errorResultBlock
                                        .textContent
                                        .replace(/[\n\r]+|[\s]{2,}/g, ' ')
                                        .trim();
        }

        if (reason)
            if (reason == 'recaptcha')
                payload.error.description = 'Recaptcha error';
            else
                payload.error.description = 'Server error';

        analytics.sendData(payload);
    }
}

register.registerClass('.widget-form-steps', FormSteps);