import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
//import { triggerWhenVisible } from '../../libs/commons';



class QuickLinks extends Component {

    constructor(container) {
        super('elem-quick-links');

        this.container = container.querySelector('.elem-quick-links__items');

        document.addEventListener('bpMobileTablet', () => this._initSwiper() );
        document.addEventListener('bpDesktop', () => this._destroySwiper() );

        if (window.deviceBreakpoints.bpMobileTablet.matches === true)
            this._initSwiper();
    }

    async _initSwiper() {

        if (!this.swiper) {
            const Swiper = await import('swiper');
            this.swiper = new Swiper.default(this.container, {
                slidesPerView: '1',
                grabCursor: true,
                observer: true,
                watchOverflow: true,
                spaceBetween: 32,
                /*on: {
                    slideChangeTransitionEnd: (swiper) => {
                        const activeSlide = this.container.querySelector('.swiper-slide-active');
                        const title = activeSlide.querySelector(`${this._el('item-title',true)}`);
                        const cta = activeSlide.querySelector('a[href]');
                        //console.log(swiper,activeSlide);
                        this._sendAnalytics(
                            'impression',
                            title.textContent.trim(),
                            cta.href,
                            swiper.realIndex + 1,
                        );
                    }
                }*/
            });

            /*const ctaList = this.container.querySelectorAll(`${this._el('items',true)} a[href]`);
            ctaList.forEach((item) => {
                item.addEventListener('click', ({target}) => {
                    this._sendAnalytics(
                        'click',
                        target.textContent.trim(),
                        target.href,
                        this.swiper.realIndex + 1,
                    );
                });
            });

            triggerWhenVisible(this.container, () => {
                const slides = this.container.querySelectorAll(`${this._el('item',true)}`);

                if (slides) {
                    const title = slides[0].querySelector(`${this._el('item-title',true)}`);
                    const cta = slides[0].querySelector('a[href]');
            
                    this._sendAnalytics(
                        'impression',
                        title.textContent.trim(),
                        cta.href,
                        1,
                    );
                }
            }, true);*/

        } else {
            this.swiper.update();
        }
    }

    _destroySwiper() {

        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }

    _sendAnalytics(action,label,url,position) {

        analytics.sendData({
            'event': 'slider',
            'action': action,           // "impression" or "click"
            'label': label,             // Product name
            'click_url': url || '',     // Primary CTA href
            'position': position        // Swiper slide position
        });
    }
}

register.registerClass('.elem-quick-links', QuickLinks);