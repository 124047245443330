import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { triggerWhenVisible, triggerWhenNotVisible } from '../../libs/commons';
import { forceUpdateLazy, resetImageLazy } from '../../components/media-picture';




class Slideshow extends Component {

    constructor(container) {
        super('widget-slideshow');

        this.isVisible = false;
        this.swiperExternalDiv = container;
        this.container = container.querySelector(`${this._el('container', true)}`);
        this.autoHeightComponent = false;
        this._initSwiper(container);
    }

    async _initSwiper() {
        if (this.swiper) {
            this.container.style.height = '';
            this.container.classList.remove(`${this._el('container--fixedHeight', false)}`);
            this._destroySwiper();
        }

        this.autoHeightComponent = !!this.container.querySelector('.widget-hero');
        let desktopFixedHeight = this.swiperExternalDiv.dataset.heightDesktop;
        let tabletFixedHeight = this.swiperExternalDiv.dataset.heightTablet;
        let mobileFixedHeight = this.swiperExternalDiv.dataset.heightMobile;

        if (window.deviceBreakpoints.bpDesktop.matches === true && (desktopFixedHeight && desktopFixedHeight != '')) {
            this.container.style.height = parseInt(desktopFixedHeight) + 'px';
            this.autoHeightComponent = false;
            this.container.classList.add(`${this._el('container--fixedHeight', false)}`);
        }

        if (window.deviceBreakpoints.bpTablet.matches === true && (tabletFixedHeight && tabletFixedHeight != '')) {
            this.container.style.height = parseInt(tabletFixedHeight) + 'px';
            this.autoHeightComponent = false;
            this.container.classList.add(`${this._el('container--fixedHeight', false)}`);
        }

        if (window.deviceBreakpoints.bpMobile.matches === true && (mobileFixedHeight && mobileFixedHeight != '')) {
            this.container.style.height = parseInt(mobileFixedHeight) + 'px';
            this.autoHeightComponent = false;
            this.container.classList.add(`${this._el('container--fixedHeight', false)}`);

        }


        const Swiper = await import('swiper');

        const userDelay = parseInt(this.container.dataset.delay);
        const userSpeed = parseInt(this.container.dataset.speed);
        let config = {
            modules: [Swiper.Autoplay, Swiper.EffectFade, Swiper.Navigation, Swiper.Pagination],
            slidesPerView: '1',
            speed: userSpeed && userSpeed >= 300 && userSpeed <= 5000 ? userSpeed : 700,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoHeight: this.autoHeightComponent, // When an Hero is used as a slide enable autoHeight
            roundLengths: true,
            observer: true,
            watchOverflow: true,
            preventInteractionOnTransition: true,
            simulateTouch: false,
            nested: true,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            on: {
                slideChange: (swiper) => {
                    const slides = swiper.el.querySelectorAll('.swiper-slide:not(.swiper-slide-active)');
                    const activeSlide = swiper.el.querySelector('.swiper-slide-active');

                    if (activeSlide) {
                        const buttons = activeSlide.querySelectorAll('.elem-cta-modal');
                        buttons.forEach((el) => {
                            if (!el.objReference)
                                register.applyTo(el);
                        });
                    }

                    slides.forEach((el) => {    // Resetting cloned images
                        resetImageLazy(el.querySelector('.media-picture--lazy .media-picture__img'));
                    });

                    forceUpdateLazy();
                },
                slideChangeTransitionEnd: (swiper) => {
                    if (this.isVisible)
                        this._sendAnalytics(this._getDataForAnalytics(swiper.realIndex));
                }
            }
        };

        if (userDelay || userSpeed) // If both properties are not used autoplay will not be enabled
            config.autoplay = {
                delay: userDelay && userDelay >= 1 && userDelay <= 15 ? userDelay * 1000 : 7000,
                disableOnInteraction: false
            }


        this.swiper = new Swiper.default(this.container, config);

        const ctaList = this.container.querySelectorAll(`.widget-hero__cta, ${this._el('slide-cta', true)}`);
        ctaList.forEach((item) => {
            item.addEventListener('click', ({ target }) => {
                const ctaUrl = target.href || null;
                this._sendAnalytics(this._getDataForAnalytics(this.swiper.realIndex, ctaUrl));
            });
        });

        triggerWhenVisible(this.container, () => {
            this.isVisible = true;
            this._sendAnalytics(this._getDataForAnalytics(this.swiper.realIndex));
            triggerWhenNotVisible(this.container, () => this.isVisible = false, true, 30);
        }, false, 70);

        if(this.container.classList.contains(`${this._el('container--fixedHeight', false)}`)) {
            this.container.style.removeProperty('min-height');
        }

        /*this.swiper.slides.forEach((el) => {
            el.addEventListener('mediaPictureLoaded', () => {
                console.log('Picture loaded');
                this.swiper.updateSize(); });
        });*/

        document.addEventListener('bpDesktop', () => {
            this._initSwiper();
        });
        document.addEventListener('bpTablet', () => {
            this._initSwiper();
        });

        document.addEventListener('bpMobile', () => {
            this._initSwiper();
        });
    }

    _pauseSlideshow() {

        if (this.swiper)
            if (this.swiper.autoplay.running) {
                this.swiper.autoplay.stop();
                console.info('Carousel slideshow on PAUSE');
            }
    }

    _playSlideshow() {

        if (this.swiper)
            if (!this.swiper.autoplay.running) {
                this.swiper.autoplay.start();
                console.info('Carousel slideshow STARTED');
            }
    }

    _getDataForAnalytics(swiperIndex, ctaUrl = null) {

        const activeSlide = this.container.querySelector('.swiper-slide-active');
        const preTitle = activeSlide.querySelector('.widget-hero__pre-title');
        const title = activeSlide.querySelector(`.widget-hero__title, ${this._el('slide-title', true)}`);
        const cta = activeSlide.querySelector(`.widget-hero__cta, ${this._el('slide-cta', true)}`);

        return {
            action: ctaUrl ? 'click' : 'impression',
            label: preTitle ? preTitle.textContent.trim() : title.textContent.trim(),
            url: ctaUrl ? ctaUrl : cta ? cta.href : null,
            index: swiperIndex + 1,
        };
    }

    _sendAnalytics(data) {

        analytics.sendData({
            'event': 'slider',
            'action': data.action,      // "impression" or "click"
            'label': data.label,        // Product name
            'click_url': data.url,      // Primary CTA href
            'position': data.index      // Swiper slide position
        });
    }


    _destroySwiper() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }
}

register.registerClass('.widget-slideshow', Slideshow);