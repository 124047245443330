import Component from '../../libs/component';
import { register } from '../../libs/register';



class LogosGrid extends Component {

    constructor(container) {
        super('widget-logos-grid');

        this.grid = container;
        this.items = container.querySelectorAll(this._el('item', true));
        this.loadButton = container.querySelector('.elem-cta-load-more');

        this._logosInit()
        this._addCommonInit();
    }

    _addCommonInit() {

        document.addEventListener('bpMobile', () => this._logosInit() );
        document.addEventListener('bpTablet', () => this._logosInit() );
        document.addEventListener('bpDesktop', () => this._logosInit() );

        if (this.loadButton) {
            
            this.loadButton.addEventListener('click', (ev) => {
                ev.preventDefault();
                this._loadMore();
            });
        }
    }

    _logosInit() {
        // When viewport changed, reset and hide the correct number of items
        this._showAll();

        this.itemsCount = window.deviceBreakpoints.bpMobile.matches === true ? 6 : 12;

        if (this.items.length > this.itemsCount) {

            this.items.forEach((item, index) => {
                if (index >= this.itemsCount) {
                    item.classList.remove('show');
                    item.classList.add('hidden');
                }
            });

            this.grid.classList.remove('show-all');
            this.loadButton.classList.remove('cta-hidden');
        } else {
            this.loadButton.classList.add('cta-hidden');
        }
    }

    _showAll() {
        this.items.forEach((el) => {
            el.classList.add('show');
            el.classList.remove('hidden');
        });

        this.grid.classList.add('show-all');
    }

    _reset() {
        this.grid.classList.remove('show-all');
        this._logosInit();
    }

    _loadMore() {
        const showAll = this.grid.classList.contains('show-all')
        
        if(showAll){
           this._reset();
        } else {
            this._showAll();
        }
    }
}

/*const hideLogos = () => {
    const logo = document.querySelectorAll('.widget-logos-grid__item');
    const btn = document.querySelector('.elem-cta-load-more');
    const itemsCount = 6;

    if(logo.length > itemsCount) {
        logo.forEach((item, index) => {
            if (index >= itemsCount) {
                item.classList.remove('show');
                item.classList.add('hidden');
            }
        });

        btn.classList.remove('cta-hidden');
    }
}

(() => {

    hideLogos();

})();*/

register.registerClass('.widget-logos-grid', LogosGrid);