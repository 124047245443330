//REGISTER
import { register } from '../libs/register';

////COMPONENTS
// Base structure
import './base-page';
import './base-header';
import './base-header-lite';
import './base-sticky-menu';

// // Layout
import './layout-masonry';
// import './layout-wrap-text';
import './layout-prelogin';

// // Elements
import './elem-custom-select';
import './elem-custom-select-filter-table';
import './elem-quick-links';
import './elem-cta-modal';
import './elem-card';
import './elem-accordion';
import './elem-accordion-faq';
import './elem-searchbar';
import './elem-iframe';
import './elem-icon-anim';
// import './elem-slideshow';

// // Media
import './media-picture';
import './media-video';

// //Widget
import './widget-modal';
import './widget-modal-comparison';
import './widget-modal-form';
import './widget-form-steps';
import './widget-accordion-select';
// import './widget-accordion-panel';
import './widget-tab';
import './widget-table';
import './widget-share';
// import './widget-cards-list';
import './widget-slideshow';
import './widget-products-slider';
import './widget-round-images';
import './widget-logos-grid';
import './widget-chatbot';
// import './widget-gallery';

// import './widget-calendar-events';

// import './widget-country-hero';
// import './widget-past-events';

register.apply(document);
